<template>
  <div>
    <!--  落地页重定向页面  -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import API from '@/api';

  export default {
    name: 'index',
    async asyncData({ store, $http, params }) {
      const { business_share_url, quick_app } = await $http.post(API.GET_REDIRECT_PAGE_INFO, {
        ...params,
      });
      store.commit('share/redirectUrl/SET_REDIRECT_URL', business_share_url || '');
      store.commit('share/redirectUrl/SET_QUICK_INFO', quick_app || {});
    },
    computed: {
      ...mapGetters('share/redirectUrl', ['RedirectUrl', 'QuickInfo']),
    },
    beforeMount() {
      try {
        let current_url = window.location.href;
        let target_url = current_url.replace(current_url.split('?')[0], this.RedirectUrl);
        window.location.replace(target_url);
      } catch (e) {
        console.log(e);
      }
      try {
        // 判断跳转快应用
        let { is_quick_app, business, quick_app_url, package: packageName } = this.QuickInfo;
        if (window.channelReady && is_quick_app && quick_app_url) {
          window.channelReady((bAvailable) => {
            if (window.appRouter && bAvailable) {
              let query = { businessIndex: business === 'music' ? 0 : 1, ...this.$route.query };
              window.appRouter(packageName, quick_app_url, query);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  };
</script>

<style scoped></style>
